import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _27eb63ad = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _308a3b5c = () => interopDefault(import('../pages/d3s1gNgU1d3/index.vue' /* webpackChunkName: "pages/d3s1gNgU1d3/index" */))
const _534fb40a = () => interopDefault(import('../pages/email-confirmation.vue' /* webpackChunkName: "pages/email-confirmation" */))
const _59b8baa6 = () => interopDefault(import('../pages/location/index.vue' /* webpackChunkName: "pages/location/index" */))
const _721ddc2c = () => interopDefault(import('../pages/manage-accounts/index.vue' /* webpackChunkName: "pages/manage-accounts/index" */))
const _4960a1b5 = () => interopDefault(import('../pages/my-events.vue' /* webpackChunkName: "pages/my-events" */))
const _65df0d1d = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _9d550c88 = () => interopDefault(import('../pages/pdf-templates/index.vue' /* webpackChunkName: "pages/pdf-templates/index" */))
const _979f0072 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _e6faccee = () => interopDefault(import('../pages/reporting.vue' /* webpackChunkName: "pages/reporting" */))
const _5dc250fc = () => interopDefault(import('../pages/send-newsletter/index.vue' /* webpackChunkName: "pages/send-newsletter/index" */))
const _68e8eeca = () => interopDefault(import('../pages/send-notifications/index.vue' /* webpackChunkName: "pages/send-notifications/index" */))
const _f1a8d634 = () => interopDefault(import('../pages/transaction-rules/index.vue' /* webpackChunkName: "pages/transaction-rules/index" */))
const _31a68e26 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _35357b32 = () => interopDefault(import('../pages/nft-collectibles/air-drops/index.vue' /* webpackChunkName: "pages/nft-collectibles/air-drops/index" */))
const _25a1cd26 = () => interopDefault(import('../pages/nft-collectibles/collections/index.vue' /* webpackChunkName: "pages/nft-collectibles/collections/index" */))
const _6f9a5839 = () => interopDefault(import('../pages/organizer/_organizerName/edit.vue' /* webpackChunkName: "" */))
const _2a4adafa = () => interopDefault(import('../pages/organizer/invite-organizer/index.vue' /* webpackChunkName: "pages/organizer/invite-organizer/index" */))
const _0ea33133 = () => interopDefault(import('../pages/organizer/list.vue' /* webpackChunkName: "pages/organizer/list" */))
const _bde3eefc = () => interopDefault(import('../pages/pdf-templates/create.vue' /* webpackChunkName: "pages/pdf-templates/create" */))
const _9aa4918e = () => interopDefault(import('../pages/profile/password/index.vue' /* webpackChunkName: "pages/profile/password/index" */))
const _bc681e00 = () => interopDefault(import('../pages/profile/privacy/index.vue' /* webpackChunkName: "pages/profile/privacy/index" */))
const _fb87cd7c = () => interopDefault(import('../pages/send-newsletter/create.vue' /* webpackChunkName: "pages/send-newsletter/create" */))
const _64cc54fa = () => interopDefault(import('../pages/send-notifications/create.vue' /* webpackChunkName: "pages/send-notifications/create" */))
const _9fd075b6 = () => interopDefault(import('../pages/transaction-rules/new-rule.vue' /* webpackChunkName: "pages/transaction-rules/new-rule" */))
const _9367bb50 = () => interopDefault(import('../pages/nft-collectibles/collections/create.vue' /* webpackChunkName: "pages/nft-collectibles/collections/create" */))
const _0532a50f = () => interopDefault(import('../pages/nft-collectibles/collections/_collectionId/index.vue' /* webpackChunkName: "pages/nft-collectibles/collections/_collectionId/index" */))
const _f070e608 = () => interopDefault(import('../pages/nft-collectibles/air-drops/_itemId/edit.vue' /* webpackChunkName: "pages/nft-collectibles/air-drops/_itemId/edit" */))
const _71a9205d = () => interopDefault(import('../pages/nft-collectibles/collections/_collectionId/edit.vue' /* webpackChunkName: "pages/nft-collectibles/collections/_collectionId/edit" */))
const _2ec630fe = () => interopDefault(import('../pages/nft-collectibles/collections/_collectionId/items/create.vue' /* webpackChunkName: "pages/nft-collectibles/collections/_collectionId/items/create" */))
const _066434de = () => interopDefault(import('../pages/nft-collectibles/collections/_collectionId/items/_itemId/index.vue' /* webpackChunkName: "pages/nft-collectibles/collections/_collectionId/items/_itemId/index" */))
const _6f15ea0c = () => interopDefault(import('../pages/nft-collectibles/collections/_collectionId/items/_itemId/create-airdrop.vue' /* webpackChunkName: "pages/nft-collectibles/collections/_collectionId/items/_itemId/create-airdrop" */))
const _6efa26f8 = () => interopDefault(import('../pages/nft-collectibles/collections/_collectionId/items/_itemId/direct-send.vue' /* webpackChunkName: "pages/nft-collectibles/collections/_collectionId/items/_itemId/direct-send" */))
const _612edaae = () => interopDefault(import('../pages/nft-collectibles/collections/_collectionId/items/_itemId/edit.vue' /* webpackChunkName: "pages/nft-collectibles/collections/_collectionId/items/_itemId/edit" */))
const _45f7889d = () => interopDefault(import('../pages/profile/_userId/index.vue' /* webpackChunkName: "pages/profile/_userId/index" */))
const _af5e52ea = () => interopDefault(import('../pages/reset-password/_code.vue' /* webpackChunkName: "pages/reset-password/_code" */))
const _a4aa4f82 = () => interopDefault(import('../pages/location/_locationId/edit.vue' /* webpackChunkName: "pages/location/_locationId/edit" */))
const _1f84d042 = () => interopDefault(import('../pages/organizer/_organizerName/fees/index.vue' /* webpackChunkName: "pages/organizer/_organizerName/fees/index" */))
const _a8c2544a = () => interopDefault(import('../pages/pdf-templates/_pdfTemplateId/edit.vue' /* webpackChunkName: "pages/pdf-templates/_pdfTemplateId/edit" */))
const _2cb73968 = () => interopDefault(import('../pages/send-newsletter/_newsletterId/edit.vue' /* webpackChunkName: "pages/send-newsletter/_newsletterId/edit" */))
const _53019aca = () => interopDefault(import('../pages/send-notifications/_notificationId/edit.vue' /* webpackChunkName: "pages/send-notifications/_notificationId/edit" */))
const _de3a13e8 = () => interopDefault(import('../pages/send-notifications/_notificationId/resend.vue' /* webpackChunkName: "pages/send-notifications/_notificationId/resend" */))
const _e1392d82 = () => interopDefault(import('../pages/transaction-rules/_ruleId/edit.vue' /* webpackChunkName: "pages/transaction-rules/_ruleId/edit" */))
const _80021a46 = () => interopDefault(import('../pages/_tenant/event/_id/index.vue' /* webpackChunkName: "pages/_tenant/event/_id/index" */))
const _81148dea = () => interopDefault(import('../pages/_tenant/event/_id/confirm.vue' /* webpackChunkName: "pages/_tenant/event/_id/confirm" */))
const _4744c1dc = () => interopDefault(import('../pages/_tenant/event/_id/holders.vue' /* webpackChunkName: "pages/_tenant/event/_id/holders" */))
const _56d33dfa = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/create.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/create" */))
const _d5c0551c = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/discounts/index.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/discounts/index" */))
const _7844d986 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/edit.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/edit" */))
const _0364d342 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/occurrences.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/occurrences" */))
const _5ed36c5b = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/orders/index.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/orders/index" */))
const _da4082a8 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/publish.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/publish" */))
const _06b299ea = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/reporting.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/reporting" */))
const _64f3376d = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/sectors/index.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/sectors/index" */))
const _afc01ac8 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/send-emails/index.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/send-emails/index" */))
const _59a4fad9 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/send-notifications/index.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/send-notifications/index" */))
const _d569fad0 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/send-tickets/index.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/send-tickets/index" */))
const _095d8007 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/tickets/index.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/tickets/index" */))
const _5efde84a = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/tickets-media.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/tickets-media" */))
const _97d9dbb0 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/timeslots/index.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/timeslots/index" */))
const _368f238c = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/discounts/create.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/discounts/create" */))
const _5331d692 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/questionnaire/add.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/questionnaire/add" */))
const _ad1611f8 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/questionnaire/manage.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/questionnaire/manage" */))
const _211e8b63 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/seatmap/chart.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/seatmap/chart" */))
const _0c12bfb1 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/seatmap/manage-seats.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/seatmap/manage-seats" */))
const _612800f1 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/sectors/create.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/sectors/create" */))
const _00183b79 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/send-emails/new-email.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/send-emails/new-email" */))
const _4a06cb97 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/tickets/create.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/tickets/create" */))
const _5c84f18e = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/tickets/promotions/index.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/tickets/promotions/index" */))
const _13f904d4 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/timeslots/create.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/timeslots/create" */))
const _5bcd8af0 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/tickets/promotions/create.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/tickets/promotions/create" */))
const _e413b964 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/discounts/_discountId/edit.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/discounts/_discountId/edit" */))
const _4fe48aae = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/sectors/_sectorId/edit.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/sectors/_sectorId/edit" */))
const _477f116b = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/send-emails/_emailId/edit.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/send-emails/_emailId/edit" */))
const _32576a8e = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/tickets/_ticketConfigId/duplicate.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/tickets/_ticketConfigId/duplicate" */))
const _2c0c8568 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/tickets/_ticketConfigId/edit.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/tickets/_ticketConfigId/edit" */))
const _39b794e4 = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/timeslots/_timeslotId/edit.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/timeslots/_timeslotId/edit" */))
const _69b506db = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/tickets/_ticketConfigId/conditional-tickets/edit.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/tickets/_ticketConfigId/conditional-tickets/edit" */))
const _3728eb5d = () => interopDefault(import('../pages/_tenant/organizer/_organizerName/event/_id/tickets/_ticketConfigId/promotions/_promotionId/edit.vue' /* webpackChunkName: "pages/_tenant/organizer/_organizerName/event/_id/tickets/_ticketConfigId/promotions/_promotionId/edit" */))
const _5a0677d9 = () => interopDefault(import('../pages/_provider/_id/index.vue' /* webpackChunkName: "pages/_provider/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _27eb63ad,
    props: true,
    name: "index___de"
  }, {
    path: "/en",
    component: _27eb63ad,
    props: true,
    name: "index___en"
  }, {
    path: "/sr",
    component: _27eb63ad,
    props: true,
    name: "index___sr"
  }, {
    path: "/de/d3s1gNgU1d3",
    component: _308a3b5c,
    props: true,
    name: "d3s1gNgU1d3___de"
  }, {
    path: "/de/email-confirmation",
    component: _534fb40a,
    props: true,
    name: "email-confirmation___de"
  }, {
    path: "/de/location",
    component: _59b8baa6,
    props: true,
    name: "location___de"
  }, {
    path: "/de/manage-accounts",
    component: _721ddc2c,
    props: true,
    name: "manage-accounts___de"
  }, {
    path: "/de/my-events",
    component: _4960a1b5,
    props: true,
    name: "my-events___de"
  }, {
    path: "/de/orders",
    component: _65df0d1d,
    props: true,
    name: "orders___de"
  }, {
    path: "/de/pdf-templates",
    component: _9d550c88,
    props: true,
    name: "pdf-templates___de"
  }, {
    path: "/de/profile",
    component: _979f0072,
    props: true,
    name: "profile___de"
  }, {
    path: "/de/reporting",
    component: _e6faccee,
    props: true,
    name: "reporting___de"
  }, {
    path: "/de/send-newsletter",
    component: _5dc250fc,
    props: true,
    name: "send-newsletter___de"
  }, {
    path: "/de/send-notifications",
    component: _68e8eeca,
    props: true,
    name: "send-notifications___de"
  }, {
    path: "/de/transaction-rules",
    component: _f1a8d634,
    props: true,
    name: "transaction-rules___de"
  }, {
    path: "/de/users",
    component: _31a68e26,
    props: true,
    name: "users___de"
  }, {
    path: "/en/d3s1gNgU1d3",
    component: _308a3b5c,
    props: true,
    name: "d3s1gNgU1d3___en"
  }, {
    path: "/en/email-confirmation",
    component: _534fb40a,
    props: true,
    name: "email-confirmation___en"
  }, {
    path: "/en/location",
    component: _59b8baa6,
    props: true,
    name: "location___en"
  }, {
    path: "/en/manage-accounts",
    component: _721ddc2c,
    props: true,
    name: "manage-accounts___en"
  }, {
    path: "/en/my-events",
    component: _4960a1b5,
    props: true,
    name: "my-events___en"
  }, {
    path: "/en/orders",
    component: _65df0d1d,
    props: true,
    name: "orders___en"
  }, {
    path: "/en/pdf-templates",
    component: _9d550c88,
    props: true,
    name: "pdf-templates___en"
  }, {
    path: "/en/profile",
    component: _979f0072,
    props: true,
    name: "profile___en"
  }, {
    path: "/en/reporting",
    component: _e6faccee,
    props: true,
    name: "reporting___en"
  }, {
    path: "/en/send-newsletter",
    component: _5dc250fc,
    props: true,
    name: "send-newsletter___en"
  }, {
    path: "/en/send-notifications",
    component: _68e8eeca,
    props: true,
    name: "send-notifications___en"
  }, {
    path: "/en/transaction-rules",
    component: _f1a8d634,
    props: true,
    name: "transaction-rules___en"
  }, {
    path: "/en/users",
    component: _31a68e26,
    props: true,
    name: "users___en"
  }, {
    path: "/sr/d3s1gNgU1d3",
    component: _308a3b5c,
    props: true,
    name: "d3s1gNgU1d3___sr"
  }, {
    path: "/sr/email-confirmation",
    component: _534fb40a,
    props: true,
    name: "email-confirmation___sr"
  }, {
    path: "/sr/location",
    component: _59b8baa6,
    props: true,
    name: "location___sr"
  }, {
    path: "/sr/manage-accounts",
    component: _721ddc2c,
    props: true,
    name: "manage-accounts___sr"
  }, {
    path: "/sr/my-events",
    component: _4960a1b5,
    props: true,
    name: "my-events___sr"
  }, {
    path: "/sr/orders",
    component: _65df0d1d,
    props: true,
    name: "orders___sr"
  }, {
    path: "/sr/pdf-templates",
    component: _9d550c88,
    props: true,
    name: "pdf-templates___sr"
  }, {
    path: "/sr/profile",
    component: _979f0072,
    props: true,
    name: "profile___sr"
  }, {
    path: "/sr/reporting",
    component: _e6faccee,
    props: true,
    name: "reporting___sr"
  }, {
    path: "/sr/send-newsletter",
    component: _5dc250fc,
    props: true,
    name: "send-newsletter___sr"
  }, {
    path: "/sr/send-notifications",
    component: _68e8eeca,
    props: true,
    name: "send-notifications___sr"
  }, {
    path: "/sr/transaction-rules",
    component: _f1a8d634,
    props: true,
    name: "transaction-rules___sr"
  }, {
    path: "/sr/users",
    component: _31a68e26,
    props: true,
    name: "users___sr"
  }, {
    path: "/de/nft-collectibles/air-drops",
    component: _35357b32,
    props: true,
    name: "nft-collectibles-air-drops___de"
  }, {
    path: "/de/nft-collectibles/collections",
    component: _25a1cd26,
    props: true,
    name: "nft-collectibles-collections___de"
  }, {
    path: "/de/organizer/create",
    component: _6f9a5839,
    props: true,
    name: "organizer/create___de"
  }, {
    path: "/de/organizer/invite-organizer",
    component: _2a4adafa,
    props: true,
    name: "organizer-invite-organizer___de"
  }, {
    path: "/de/organizer/list",
    component: _0ea33133,
    props: true,
    name: "organizer-list___de"
  }, {
    path: "/de/pdf-templates/create",
    component: _bde3eefc,
    props: true,
    name: "pdf-templates-create___de"
  }, {
    path: "/de/profile/password",
    component: _9aa4918e,
    props: true,
    name: "profile-password___de"
  }, {
    path: "/de/profile/privacy",
    component: _bc681e00,
    props: true,
    name: "profile-privacy___de"
  }, {
    path: "/de/send-newsletter/create",
    component: _fb87cd7c,
    props: true,
    name: "send-newsletter-create___de"
  }, {
    path: "/de/send-notifications/create",
    component: _64cc54fa,
    props: true,
    name: "send-notifications-create___de"
  }, {
    path: "/de/transaction-rules/new-rule",
    component: _9fd075b6,
    props: true,
    name: "transaction-rules-new-rule___de"
  }, {
    path: "/en/nft-collectibles/air-drops",
    component: _35357b32,
    props: true,
    name: "nft-collectibles-air-drops___en"
  }, {
    path: "/en/nft-collectibles/collections",
    component: _25a1cd26,
    props: true,
    name: "nft-collectibles-collections___en"
  }, {
    path: "/en/organizer/create",
    component: _6f9a5839,
    props: true,
    name: "organizer/create___en"
  }, {
    path: "/en/organizer/invite-organizer",
    component: _2a4adafa,
    props: true,
    name: "organizer-invite-organizer___en"
  }, {
    path: "/en/organizer/list",
    component: _0ea33133,
    props: true,
    name: "organizer-list___en"
  }, {
    path: "/en/pdf-templates/create",
    component: _bde3eefc,
    props: true,
    name: "pdf-templates-create___en"
  }, {
    path: "/en/profile/password",
    component: _9aa4918e,
    props: true,
    name: "profile-password___en"
  }, {
    path: "/en/profile/privacy",
    component: _bc681e00,
    props: true,
    name: "profile-privacy___en"
  }, {
    path: "/en/send-newsletter/create",
    component: _fb87cd7c,
    props: true,
    name: "send-newsletter-create___en"
  }, {
    path: "/en/send-notifications/create",
    component: _64cc54fa,
    props: true,
    name: "send-notifications-create___en"
  }, {
    path: "/en/transaction-rules/new-rule",
    component: _9fd075b6,
    props: true,
    name: "transaction-rules-new-rule___en"
  }, {
    path: "/sr/nft-collectibles/air-drops",
    component: _35357b32,
    props: true,
    name: "nft-collectibles-air-drops___sr"
  }, {
    path: "/sr/nft-collectibles/collections",
    component: _25a1cd26,
    props: true,
    name: "nft-collectibles-collections___sr"
  }, {
    path: "/sr/organizer/create",
    component: _6f9a5839,
    props: true,
    name: "organizer/create___sr"
  }, {
    path: "/sr/organizer/invite-organizer",
    component: _2a4adafa,
    props: true,
    name: "organizer-invite-organizer___sr"
  }, {
    path: "/sr/organizer/list",
    component: _0ea33133,
    props: true,
    name: "organizer-list___sr"
  }, {
    path: "/sr/pdf-templates/create",
    component: _bde3eefc,
    props: true,
    name: "pdf-templates-create___sr"
  }, {
    path: "/sr/profile/password",
    component: _9aa4918e,
    props: true,
    name: "profile-password___sr"
  }, {
    path: "/sr/profile/privacy",
    component: _bc681e00,
    props: true,
    name: "profile-privacy___sr"
  }, {
    path: "/sr/send-newsletter/create",
    component: _fb87cd7c,
    props: true,
    name: "send-newsletter-create___sr"
  }, {
    path: "/sr/send-notifications/create",
    component: _64cc54fa,
    props: true,
    name: "send-notifications-create___sr"
  }, {
    path: "/sr/transaction-rules/new-rule",
    component: _9fd075b6,
    props: true,
    name: "transaction-rules-new-rule___sr"
  }, {
    path: "/de/nft-collectibles/collections/create",
    component: _9367bb50,
    props: true,
    name: "nft-collectibles-collections-create___de"
  }, {
    path: "/en/nft-collectibles/collections/create",
    component: _9367bb50,
    props: true,
    name: "nft-collectibles-collections-create___en"
  }, {
    path: "/sr/nft-collectibles/collections/create",
    component: _9367bb50,
    props: true,
    name: "nft-collectibles-collections-create___sr"
  }, {
    path: "/de/nft-collectibles/collections/:collectionId",
    component: _0532a50f,
    props: true,
    name: "nft-collectibles-collections-collectionId___de"
  }, {
    path: "/en/nft-collectibles/collections/:collectionId",
    component: _0532a50f,
    props: true,
    name: "nft-collectibles-collections-collectionId___en"
  }, {
    path: "/sr/nft-collectibles/collections/:collectionId",
    component: _0532a50f,
    props: true,
    name: "nft-collectibles-collections-collectionId___sr"
  }, {
    path: "/de/nft-collectibles/air-drops/:itemId?/edit",
    component: _f070e608,
    props: true,
    name: "nft-collectibles-air-drops-itemId-edit___de"
  }, {
    path: "/de/nft-collectibles/collections/:collectionId?/edit",
    component: _71a9205d,
    props: true,
    name: "nft-collectibles-collections-collectionId-edit___de"
  }, {
    path: "/en/nft-collectibles/air-drops/:itemId?/edit",
    component: _f070e608,
    props: true,
    name: "nft-collectibles-air-drops-itemId-edit___en"
  }, {
    path: "/en/nft-collectibles/collections/:collectionId?/edit",
    component: _71a9205d,
    props: true,
    name: "nft-collectibles-collections-collectionId-edit___en"
  }, {
    path: "/sr/nft-collectibles/air-drops/:itemId?/edit",
    component: _f070e608,
    props: true,
    name: "nft-collectibles-air-drops-itemId-edit___sr"
  }, {
    path: "/sr/nft-collectibles/collections/:collectionId?/edit",
    component: _71a9205d,
    props: true,
    name: "nft-collectibles-collections-collectionId-edit___sr"
  }, {
    path: "/de/nft-collectibles/collections/:collectionId?/items/create",
    component: _2ec630fe,
    props: true,
    name: "nft-collectibles-collections-collectionId-items-create___de"
  }, {
    path: "/en/nft-collectibles/collections/:collectionId?/items/create",
    component: _2ec630fe,
    props: true,
    name: "nft-collectibles-collections-collectionId-items-create___en"
  }, {
    path: "/sr/nft-collectibles/collections/:collectionId?/items/create",
    component: _2ec630fe,
    props: true,
    name: "nft-collectibles-collections-collectionId-items-create___sr"
  }, {
    path: "/de/nft-collectibles/collections/:collectionId?/items/:itemId",
    component: _066434de,
    props: true,
    name: "nft-collectibles-collections-collectionId-items-itemId___de"
  }, {
    path: "/en/nft-collectibles/collections/:collectionId?/items/:itemId",
    component: _066434de,
    props: true,
    name: "nft-collectibles-collections-collectionId-items-itemId___en"
  }, {
    path: "/sr/nft-collectibles/collections/:collectionId?/items/:itemId",
    component: _066434de,
    props: true,
    name: "nft-collectibles-collections-collectionId-items-itemId___sr"
  }, {
    path: "/de/nft-collectibles/collections/:collectionId?/items/:itemId/create-airdrop",
    component: _6f15ea0c,
    props: true,
    name: "nft-collectibles-collections-collectionId-items-itemId-create-airdrop___de"
  }, {
    path: "/de/nft-collectibles/collections/:collectionId?/items/:itemId/direct-send",
    component: _6efa26f8,
    props: true,
    name: "nft-collectibles-collections-collectionId-items-itemId-direct-send___de"
  }, {
    path: "/de/nft-collectibles/collections/:collectionId?/items/:itemId/edit",
    component: _612edaae,
    props: true,
    name: "nft-collectibles-collections-collectionId-items-itemId-edit___de"
  }, {
    path: "/en/nft-collectibles/collections/:collectionId?/items/:itemId/create-airdrop",
    component: _6f15ea0c,
    props: true,
    name: "nft-collectibles-collections-collectionId-items-itemId-create-airdrop___en"
  }, {
    path: "/en/nft-collectibles/collections/:collectionId?/items/:itemId/direct-send",
    component: _6efa26f8,
    props: true,
    name: "nft-collectibles-collections-collectionId-items-itemId-direct-send___en"
  }, {
    path: "/en/nft-collectibles/collections/:collectionId?/items/:itemId/edit",
    component: _612edaae,
    props: true,
    name: "nft-collectibles-collections-collectionId-items-itemId-edit___en"
  }, {
    path: "/sr/nft-collectibles/collections/:collectionId?/items/:itemId/create-airdrop",
    component: _6f15ea0c,
    props: true,
    name: "nft-collectibles-collections-collectionId-items-itemId-create-airdrop___sr"
  }, {
    path: "/sr/nft-collectibles/collections/:collectionId?/items/:itemId/direct-send",
    component: _6efa26f8,
    props: true,
    name: "nft-collectibles-collections-collectionId-items-itemId-direct-send___sr"
  }, {
    path: "/sr/nft-collectibles/collections/:collectionId?/items/:itemId/edit",
    component: _612edaae,
    props: true,
    name: "nft-collectibles-collections-collectionId-items-itemId-edit___sr"
  }, {
    path: "/de/profile/:userId",
    component: _45f7889d,
    props: true,
    name: "profile-userId___de"
  }, {
    path: "/de/reset-password/:code?",
    component: _af5e52ea,
    props: true,
    name: "reset-password-code___de"
  }, {
    path: "/en/profile/:userId",
    component: _45f7889d,
    props: true,
    name: "profile-userId___en"
  }, {
    path: "/en/reset-password/:code?",
    component: _af5e52ea,
    props: true,
    name: "reset-password-code___en"
  }, {
    path: "/sr/profile/:userId",
    component: _45f7889d,
    props: true,
    name: "profile-userId___sr"
  }, {
    path: "/sr/reset-password/:code?",
    component: _af5e52ea,
    props: true,
    name: "reset-password-code___sr"
  }, {
    path: "/de/location/:locationId/edit",
    component: _a4aa4f82,
    props: true,
    name: "location-locationId-edit___de"
  }, {
    path: "/de/organizer/:organizerName?/edit",
    component: _6f9a5839,
    props: true,
    name: "organizer-organizerName-edit___de"
  }, {
    path: "/de/organizer/:organizerName?/fees",
    component: _1f84d042,
    props: true,
    name: "organizer-organizerName-fees___de"
  }, {
    path: "/de/pdf-templates/:pdfTemplateId?/edit",
    component: _a8c2544a,
    props: true,
    name: "pdf-templates-pdfTemplateId-edit___de"
  }, {
    path: "/de/send-newsletter/:newsletterId?/edit",
    component: _2cb73968,
    props: true,
    name: "send-newsletter-newsletterId-edit___de"
  }, {
    path: "/de/send-notifications/:notificationId?/edit",
    component: _53019aca,
    props: true,
    name: "send-notifications-notificationId-edit___de"
  }, {
    path: "/de/send-notifications/:notificationId?/resend",
    component: _de3a13e8,
    props: true,
    name: "send-notifications-notificationId-resend___de"
  }, {
    path: "/de/transaction-rules/:ruleId?/edit",
    component: _e1392d82,
    props: true,
    name: "transaction-rules-ruleId-edit___de"
  }, {
    path: "/en/location/:locationId/edit",
    component: _a4aa4f82,
    props: true,
    name: "location-locationId-edit___en"
  }, {
    path: "/en/organizer/:organizerName?/edit",
    component: _6f9a5839,
    props: true,
    name: "organizer-organizerName-edit___en"
  }, {
    path: "/en/organizer/:organizerName?/fees",
    component: _1f84d042,
    props: true,
    name: "organizer-organizerName-fees___en"
  }, {
    path: "/en/pdf-templates/:pdfTemplateId?/edit",
    component: _a8c2544a,
    props: true,
    name: "pdf-templates-pdfTemplateId-edit___en"
  }, {
    path: "/en/send-newsletter/:newsletterId?/edit",
    component: _2cb73968,
    props: true,
    name: "send-newsletter-newsletterId-edit___en"
  }, {
    path: "/en/send-notifications/:notificationId?/edit",
    component: _53019aca,
    props: true,
    name: "send-notifications-notificationId-edit___en"
  }, {
    path: "/en/send-notifications/:notificationId?/resend",
    component: _de3a13e8,
    props: true,
    name: "send-notifications-notificationId-resend___en"
  }, {
    path: "/en/transaction-rules/:ruleId?/edit",
    component: _e1392d82,
    props: true,
    name: "transaction-rules-ruleId-edit___en"
  }, {
    path: "/sr/location/:locationId/edit",
    component: _a4aa4f82,
    props: true,
    name: "location-locationId-edit___sr"
  }, {
    path: "/sr/organizer/:organizerName?/edit",
    component: _6f9a5839,
    props: true,
    name: "organizer-organizerName-edit___sr"
  }, {
    path: "/sr/organizer/:organizerName?/fees",
    component: _1f84d042,
    props: true,
    name: "organizer-organizerName-fees___sr"
  }, {
    path: "/sr/pdf-templates/:pdfTemplateId?/edit",
    component: _a8c2544a,
    props: true,
    name: "pdf-templates-pdfTemplateId-edit___sr"
  }, {
    path: "/sr/send-newsletter/:newsletterId?/edit",
    component: _2cb73968,
    props: true,
    name: "send-newsletter-newsletterId-edit___sr"
  }, {
    path: "/sr/send-notifications/:notificationId?/edit",
    component: _53019aca,
    props: true,
    name: "send-notifications-notificationId-edit___sr"
  }, {
    path: "/sr/send-notifications/:notificationId?/resend",
    component: _de3a13e8,
    props: true,
    name: "send-notifications-notificationId-resend___sr"
  }, {
    path: "/sr/transaction-rules/:ruleId?/edit",
    component: _e1392d82,
    props: true,
    name: "transaction-rules-ruleId-edit___sr"
  }, {
    path: "/de/:tenant/event/:id",
    component: _80021a46,
    props: true,
    name: "tenant-event-id___de"
  }, {
    path: "/en/:tenant/event/:id",
    component: _80021a46,
    props: true,
    name: "tenant-event-id___en"
  }, {
    path: "/sr/:tenant/event/:id",
    component: _80021a46,
    props: true,
    name: "tenant-event-id___sr"
  }, {
    path: "/de/:tenant/event/:id?/confirm",
    component: _81148dea,
    props: true,
    name: "tenant-event-id-confirm___de"
  }, {
    path: "/de/:tenant/event/:id?/holders",
    component: _4744c1dc,
    props: true,
    name: "tenant-event-id-holders___de"
  }, {
    path: "/en/:tenant/event/:id?/confirm",
    component: _81148dea,
    props: true,
    name: "tenant-event-id-confirm___en"
  }, {
    path: "/en/:tenant/event/:id?/holders",
    component: _4744c1dc,
    props: true,
    name: "tenant-event-id-holders___en"
  }, {
    path: "/sr/:tenant/event/:id?/confirm",
    component: _81148dea,
    props: true,
    name: "tenant-event-id-confirm___sr"
  }, {
    path: "/sr/:tenant/event/:id?/holders",
    component: _4744c1dc,
    props: true,
    name: "tenant-event-id-holders___sr"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/create",
    component: _56d33dfa,
    props: true,
    name: "tenant-organizer-organizerName-event-create___de"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/create",
    component: _56d33dfa,
    props: true,
    name: "tenant-organizer-organizerName-event-create___en"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/create",
    component: _56d33dfa,
    props: true,
    name: "tenant-organizer-organizerName-event-create___sr"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/discounts",
    component: _d5c0551c,
    props: true,
    name: "tenant-organizer-organizerName-event-id-discounts___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/edit",
    component: _7844d986,
    props: true,
    name: "tenant-organizer-organizerName-event-id-edit___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/occurrences",
    component: _0364d342,
    props: true,
    name: "tenant-organizer-organizerName-event-id-occurrences___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/orders",
    component: _5ed36c5b,
    props: true,
    name: "tenant-organizer-organizerName-event-id-orders___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/publish",
    component: _da4082a8,
    props: true,
    name: "tenant-organizer-organizerName-event-id-publish___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/reporting",
    component: _06b299ea,
    props: true,
    name: "tenant-organizer-organizerName-event-id-reporting___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/sectors",
    component: _64f3376d,
    props: true,
    name: "tenant-organizer-organizerName-event-id-sectors___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/send-emails",
    component: _afc01ac8,
    props: true,
    name: "tenant-organizer-organizerName-event-id-send-emails___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/send-notifications",
    component: _59a4fad9,
    props: true,
    name: "tenant-organizer-organizerName-event-id-send-notifications___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/send-tickets",
    component: _d569fad0,
    props: true,
    name: "tenant-organizer-organizerName-event-id-send-tickets___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/tickets",
    component: _095d8007,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/tickets-media",
    component: _5efde84a,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-media___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/timeslots",
    component: _97d9dbb0,
    props: true,
    name: "tenant-organizer-organizerName-event-id-timeslots___de"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/discounts",
    component: _d5c0551c,
    props: true,
    name: "tenant-organizer-organizerName-event-id-discounts___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/edit",
    component: _7844d986,
    props: true,
    name: "tenant-organizer-organizerName-event-id-edit___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/occurrences",
    component: _0364d342,
    props: true,
    name: "tenant-organizer-organizerName-event-id-occurrences___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/orders",
    component: _5ed36c5b,
    props: true,
    name: "tenant-organizer-organizerName-event-id-orders___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/publish",
    component: _da4082a8,
    props: true,
    name: "tenant-organizer-organizerName-event-id-publish___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/reporting",
    component: _06b299ea,
    props: true,
    name: "tenant-organizer-organizerName-event-id-reporting___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/sectors",
    component: _64f3376d,
    props: true,
    name: "tenant-organizer-organizerName-event-id-sectors___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/send-emails",
    component: _afc01ac8,
    props: true,
    name: "tenant-organizer-organizerName-event-id-send-emails___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/send-notifications",
    component: _59a4fad9,
    props: true,
    name: "tenant-organizer-organizerName-event-id-send-notifications___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/send-tickets",
    component: _d569fad0,
    props: true,
    name: "tenant-organizer-organizerName-event-id-send-tickets___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/tickets",
    component: _095d8007,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/tickets-media",
    component: _5efde84a,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-media___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/timeslots",
    component: _97d9dbb0,
    props: true,
    name: "tenant-organizer-organizerName-event-id-timeslots___en"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/discounts",
    component: _d5c0551c,
    props: true,
    name: "tenant-organizer-organizerName-event-id-discounts___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/edit",
    component: _7844d986,
    props: true,
    name: "tenant-organizer-organizerName-event-id-edit___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/occurrences",
    component: _0364d342,
    props: true,
    name: "tenant-organizer-organizerName-event-id-occurrences___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/orders",
    component: _5ed36c5b,
    props: true,
    name: "tenant-organizer-organizerName-event-id-orders___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/publish",
    component: _da4082a8,
    props: true,
    name: "tenant-organizer-organizerName-event-id-publish___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/reporting",
    component: _06b299ea,
    props: true,
    name: "tenant-organizer-organizerName-event-id-reporting___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/sectors",
    component: _64f3376d,
    props: true,
    name: "tenant-organizer-organizerName-event-id-sectors___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/send-emails",
    component: _afc01ac8,
    props: true,
    name: "tenant-organizer-organizerName-event-id-send-emails___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/send-notifications",
    component: _59a4fad9,
    props: true,
    name: "tenant-organizer-organizerName-event-id-send-notifications___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/send-tickets",
    component: _d569fad0,
    props: true,
    name: "tenant-organizer-organizerName-event-id-send-tickets___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/tickets",
    component: _095d8007,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/tickets-media",
    component: _5efde84a,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-media___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/timeslots",
    component: _97d9dbb0,
    props: true,
    name: "tenant-organizer-organizerName-event-id-timeslots___sr"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/discounts/create",
    component: _368f238c,
    props: true,
    name: "tenant-organizer-organizerName-event-id-discounts-create___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/questionnaire/add",
    component: _5331d692,
    props: true,
    name: "tenant-organizer-organizerName-event-id-questionnaire-add___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/questionnaire/manage",
    component: _ad1611f8,
    props: true,
    name: "tenant-organizer-organizerName-event-id-questionnaire-manage___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/seatmap/chart",
    component: _211e8b63,
    props: true,
    name: "tenant-organizer-organizerName-event-id-seatmap-chart___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/seatmap/manage-seats",
    component: _0c12bfb1,
    props: true,
    name: "tenant-organizer-organizerName-event-id-seatmap-manage-seats___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/sectors/create",
    component: _612800f1,
    props: true,
    name: "tenant-organizer-organizerName-event-id-sectors-create___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/send-emails/new-email",
    component: _00183b79,
    props: true,
    name: "tenant-organizer-organizerName-event-id-send-emails-new-email___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/tickets/create",
    component: _4a06cb97,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-create___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/tickets/promotions",
    component: _5c84f18e,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-promotions___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/timeslots/create",
    component: _13f904d4,
    props: true,
    name: "tenant-organizer-organizerName-event-id-timeslots-create___de"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/discounts/create",
    component: _368f238c,
    props: true,
    name: "tenant-organizer-organizerName-event-id-discounts-create___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/questionnaire/add",
    component: _5331d692,
    props: true,
    name: "tenant-organizer-organizerName-event-id-questionnaire-add___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/questionnaire/manage",
    component: _ad1611f8,
    props: true,
    name: "tenant-organizer-organizerName-event-id-questionnaire-manage___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/seatmap/chart",
    component: _211e8b63,
    props: true,
    name: "tenant-organizer-organizerName-event-id-seatmap-chart___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/seatmap/manage-seats",
    component: _0c12bfb1,
    props: true,
    name: "tenant-organizer-organizerName-event-id-seatmap-manage-seats___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/sectors/create",
    component: _612800f1,
    props: true,
    name: "tenant-organizer-organizerName-event-id-sectors-create___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/send-emails/new-email",
    component: _00183b79,
    props: true,
    name: "tenant-organizer-organizerName-event-id-send-emails-new-email___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/tickets/create",
    component: _4a06cb97,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-create___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/tickets/promotions",
    component: _5c84f18e,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-promotions___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/timeslots/create",
    component: _13f904d4,
    props: true,
    name: "tenant-organizer-organizerName-event-id-timeslots-create___en"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/discounts/create",
    component: _368f238c,
    props: true,
    name: "tenant-organizer-organizerName-event-id-discounts-create___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/questionnaire/add",
    component: _5331d692,
    props: true,
    name: "tenant-organizer-organizerName-event-id-questionnaire-add___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/questionnaire/manage",
    component: _ad1611f8,
    props: true,
    name: "tenant-organizer-organizerName-event-id-questionnaire-manage___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/seatmap/chart",
    component: _211e8b63,
    props: true,
    name: "tenant-organizer-organizerName-event-id-seatmap-chart___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/seatmap/manage-seats",
    component: _0c12bfb1,
    props: true,
    name: "tenant-organizer-organizerName-event-id-seatmap-manage-seats___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/sectors/create",
    component: _612800f1,
    props: true,
    name: "tenant-organizer-organizerName-event-id-sectors-create___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/send-emails/new-email",
    component: _00183b79,
    props: true,
    name: "tenant-organizer-organizerName-event-id-send-emails-new-email___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/tickets/create",
    component: _4a06cb97,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-create___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/tickets/promotions",
    component: _5c84f18e,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-promotions___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/timeslots/create",
    component: _13f904d4,
    props: true,
    name: "tenant-organizer-organizerName-event-id-timeslots-create___sr"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/tickets/promotions/create",
    component: _5bcd8af0,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-promotions-create___de"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/tickets/promotions/create",
    component: _5bcd8af0,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-promotions-create___en"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/tickets/promotions/create",
    component: _5bcd8af0,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-promotions-create___sr"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/discounts/:discountId/edit",
    component: _e413b964,
    props: true,
    name: "tenant-organizer-organizerName-event-id-discounts-discountId-edit___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/sectors/:sectorId/edit",
    component: _4fe48aae,
    props: true,
    name: "tenant-organizer-organizerName-event-id-sectors-sectorId-edit___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/send-emails/:emailId?/edit",
    component: _477f116b,
    props: true,
    name: "tenant-organizer-organizerName-event-id-send-emails-emailId-edit___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/tickets/:ticketConfigId/duplicate",
    component: _32576a8e,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-ticketConfigId-duplicate___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/tickets/:ticketConfigId/edit",
    component: _2c0c8568,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-ticketConfigId-edit___de"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/timeslots/:timeslotId/edit",
    component: _39b794e4,
    props: true,
    name: "tenant-organizer-organizerName-event-id-timeslots-timeslotId-edit___de"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/discounts/:discountId/edit",
    component: _e413b964,
    props: true,
    name: "tenant-organizer-organizerName-event-id-discounts-discountId-edit___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/sectors/:sectorId/edit",
    component: _4fe48aae,
    props: true,
    name: "tenant-organizer-organizerName-event-id-sectors-sectorId-edit___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/send-emails/:emailId?/edit",
    component: _477f116b,
    props: true,
    name: "tenant-organizer-organizerName-event-id-send-emails-emailId-edit___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/tickets/:ticketConfigId/duplicate",
    component: _32576a8e,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-ticketConfigId-duplicate___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/tickets/:ticketConfigId/edit",
    component: _2c0c8568,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-ticketConfigId-edit___en"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/timeslots/:timeslotId/edit",
    component: _39b794e4,
    props: true,
    name: "tenant-organizer-organizerName-event-id-timeslots-timeslotId-edit___en"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/discounts/:discountId/edit",
    component: _e413b964,
    props: true,
    name: "tenant-organizer-organizerName-event-id-discounts-discountId-edit___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/sectors/:sectorId/edit",
    component: _4fe48aae,
    props: true,
    name: "tenant-organizer-organizerName-event-id-sectors-sectorId-edit___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/send-emails/:emailId?/edit",
    component: _477f116b,
    props: true,
    name: "tenant-organizer-organizerName-event-id-send-emails-emailId-edit___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/tickets/:ticketConfigId/duplicate",
    component: _32576a8e,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-ticketConfigId-duplicate___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/tickets/:ticketConfigId/edit",
    component: _2c0c8568,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-ticketConfigId-edit___sr"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/timeslots/:timeslotId/edit",
    component: _39b794e4,
    props: true,
    name: "tenant-organizer-organizerName-event-id-timeslots-timeslotId-edit___sr"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/tickets/:ticketConfigId/conditional-tickets/edit",
    component: _69b506db,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-ticketConfigId-conditional-tickets-edit___de"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/tickets/:ticketConfigId/conditional-tickets/edit",
    component: _69b506db,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-ticketConfigId-conditional-tickets-edit___en"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/tickets/:ticketConfigId/conditional-tickets/edit",
    component: _69b506db,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-ticketConfigId-conditional-tickets-edit___sr"
  }, {
    path: "/de/:tenant/organizer/:organizerName?/event/:id?/tickets/:ticketConfigId/promotions/:promotionId?/edit",
    component: _3728eb5d,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-ticketConfigId-promotions-promotionId-edit___de"
  }, {
    path: "/en/:tenant/organizer/:organizerName?/event/:id?/tickets/:ticketConfigId/promotions/:promotionId?/edit",
    component: _3728eb5d,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-ticketConfigId-promotions-promotionId-edit___en"
  }, {
    path: "/sr/:tenant/organizer/:organizerName?/event/:id?/tickets/:ticketConfigId/promotions/:promotionId?/edit",
    component: _3728eb5d,
    props: true,
    name: "tenant-organizer-organizerName-event-id-tickets-ticketConfigId-promotions-promotionId-edit___sr"
  }, {
    path: "/sr/:tenant/event/:id/*",
    component: _80021a46,
    props: true,
    name: "tenant/event/id/catchall___sr"
  }, {
    path: "/de/:tenant/event/:id/*",
    component: _80021a46,
    props: true,
    name: "tenant/event/id/catchall___de"
  }, {
    path: "/en/:tenant/event/:id/*",
    component: _80021a46,
    props: true,
    name: "tenant/event/id/catchall___en"
  }, {
    path: "/de/:provider/:id",
    component: _5a0677d9,
    props: true,
    name: "provider-id___de"
  }, {
    path: "/en/:provider/:id",
    component: _5a0677d9,
    props: true,
    name: "provider-id___en"
  }, {
    path: "/sr/:provider/:id",
    component: _5a0677d9,
    props: true,
    name: "provider-id___sr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
