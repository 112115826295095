export default ($api, base) => ({
  organizerUrl() {
    return `${base.accountServiceUrl()}/organizer`;
  },
  seatsUrl() {
    return `${base.accountServiceUrl()}/seats-workspace`;
  },
  async getOrganizer(organizerName, fields) {
    const response = await $api.$get(
      `${this.organizerUrl()}/${organizerName}`,
      {
        params: {
          fields: fields || 'tos'
        }
      }
    );
    return response?.data;
  },
  async getSeatsioSecret(organizerName) {
    const response = await $api.$get(`${this.seatsUrl()}`, {
      params: {
        organizerName
      }
    });
    return response?.data.length > 0 ? response.data[0] : null;
  }
});
