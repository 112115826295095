import TicketheadColors from '@bam/whitelabel-sdk/dist/colors/tickethead';

const whitelabel = {
  lightTheme: {
    textPrimary: {
      base: TicketheadColors.Text.Primary[100], // Text.Primary.100
      lighten1: TicketheadColors.Text.Primary[80], // Text.Primary.80
      lighten2: TicketheadColors.Text.Primary[60], // Text.Primary.60
      lighten3: TicketheadColors.Text.Primary[40], // Text.Primary.40
      lighten4: TicketheadColors.Text.Primary[20], // Text.Primary.20
      lighten5: TicketheadColors.Text.Primary.Invers // Text.Primary.Invers
    },
    textGray: {
      base: TicketheadColors.Text.Gray[80], // Text.Gray.80
      lighten1: TicketheadColors.Text.Gray[50], // Text.Gray.50
      lighten2: TicketheadColors.Text.Gray[30] // Text.Gray.30
    },
    textHighlightError: {
      base: TicketheadColors.Text.Highlight.Error // Text.Highlight.Error
    },
    textHighlightWarning: {
      base: TicketheadColors.Text.Highlight.Warning // Text.Highlight.Warning
    },
    textHighlightSuccess: {
      base: TicketheadColors.Text.Highlight.Success // Text.Highlight.Success
    },
    textHighlightInfo: {
      base: TicketheadColors.Text.Highlight.Info // Text.Highlight.Info
    },
    backgroundPrimary: {
      base: TicketheadColors.Background.Primary[100], // Background.Primary.100
      lighten1: TicketheadColors.Background.Primary[80], // Background.Primary.80
      lighten2: TicketheadColors.Background.Primary[60], // Background.Primary.60
      lighten3: TicketheadColors.Background.Primary[40], // Background.Primary.40
      lighten4: TicketheadColors.Background.Primary[20], // Background.Primary.20
      lighten5: TicketheadColors.Background.Primary.Invers // Background.Primary.Invers
    },
    backgroundGray: {
      base: TicketheadColors.Background.Gray[100], // Background.Gray.100
      lighten1: TicketheadColors.Background.Gray[80], // Background.Gray.80
      lighten2: TicketheadColors.Background.Gray[50], // Background.Gray.50
      lighten3: TicketheadColors.Background.Gray[30] // Background.Gray.30
    },
    backgroundOverlay: {
      base: TicketheadColors.Background.Overlay.White_85 // Background.Overlay.White 85; but i have manually to set the opacity in code
    },
    backgroundHighlightError: {
      base: TicketheadColors.Background.Highlight.Error // Background.Highlight.Error
    },
    backgroundHighlightWarning: {
      base: TicketheadColors.Text.Highlight.Warning // Background.Highlight.Warning
    },
    backgroundHighlightSuccess: {
      base: TicketheadColors.Text.Highlight.Success // Background.Highlight.Success
    },
    backgroundHighlightTransparentError: {
      base: TicketheadColors.Background.Highlight.Lighten2.Error // Background.Highlight.Transparent.Error
    },
    borderPrimary: {
      base: TicketheadColors.Border.Primary[100], // Border.Primary.100
      lighten1: TicketheadColors.Border.Primary[80], // Border.Primary.80
      lighten2: TicketheadColors.Border.Primary[60], // Border.Primary.60
      lighten3: TicketheadColors.Border.Primary[40], // Border.Primary.40
      lighten4: TicketheadColors.Border.Primary[20] // Border.Primary.20
    },
    borderHighlightError: {
      base: TicketheadColors.Border.Highlight.Error // Border.Highlight.Error
    },
    borderHighlightWarning: {
      base: TicketheadColors.Border.Highlight.Warning // Border.Highlight.Warning
    },
    borderHighlightSuccess: {
      base: TicketheadColors.Border.Highlight.Success // Border.Highlight.Success
    },
    borderHighlightInfo: {
      base: TicketheadColors.Border.Highlight.Info // Border.Highlight.Info
    },
    buttonPrimary: {
      base: TicketheadColors.Button.Primary[100], // Button.Primary.100
      lighten1: TicketheadColors.Button.Primary[80], // Button.Primary.80
      lighten2: TicketheadColors.Button.Primary[60], // Button.Primary.60
      lighten3: TicketheadColors.Button.Primary[40], // Button.Primary.40
      lighten4: TicketheadColors.Button.Primary[20] // Button.Primary.20
    },
    buttonGray: {
      base: TicketheadColors.Button.Gray[80], // Button.Gray.80
      lighten1: TicketheadColors.Button.Gray[50] // Button.Gray.50
    },
    buttonHighlightError: {
      base: TicketheadColors.Button.Highlight.Error // Button.Highlight.Error
    },
    buttonHighlightTransparentError: {
      base: TicketheadColors.Button.Highlight.Transparent.Error // Button.Highlight.Transparent.Error
    },
    buttonHighlightErrorFilled: {
      base: '#C73D39'
    }
  },
  // List of supported currencies for ticket configurations
  supportedCurrencies: ['EUR', 'USD'],
  // List of supported crypto currencies
  supportedCryptoCurrencies: ['BTC', 'XBT', 'ETH', 'DAI', 'LTC', 'BCH'],

  // List of feature flags for displaying/hiding or enabling/disabling
  flags: {
    footerLogos: false
  },
  // List of URLs or links to documents and resources
  links: {
    website: 'https://www.tickethead.io/',
    // List of links in the footer
    legalNotice: 'https://www.tickethead.io/impressum-1',
    termsOfService: 'https://www.tickethead.io/agb',
    dataPrivacy: 'https://www.tickethead.io/datenschutz',
    FAQAndSupport: 'https://www.tickethead.io/kontakt',
    // List of enrollment  links
    playStoreApp:
      'https://play.google.com/store/apps/details?id=io.tickethead.wallet',
    appStoreApp: 'https://apps.apple.com/us/app/tickethead-wallet/id6462464893',
  }
};

export default whitelabel;
export type Whitelabel = typeof whitelabel;
