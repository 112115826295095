export default ($api, base) => ({
  orderBookUrl(tenant) {
    return `${base.orderBookServiceUrl(tenant)}`;
  },
  orderUrl(tenant) {
    return `${base.orderBookServiceUrl(tenant)}/order`;
  },
  async getOffers(tenant, eventId) {
    return await $api.$get(`${this.orderBookUrl(tenant)}/offer`, {
      params: {
        eventId
      }
    });
  },
  async createOrder(orderData, tenant) {
    return await $api.$post(this.orderUrl(tenant), orderData);
  }
});
