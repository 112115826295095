const PROTOCOL = 'https';

export default (baseUrl: string) => ({
  baseUrl() {
    return baseUrl;
  },

  bamgregatorUrl() {
    return `${PROTOCOL}://${baseUrl}/v1`;
  },

  eventServiceUrl(tenant: string) {
    return `${PROTOCOL}://${tenant}.${baseUrl}/event/v1`;
  },

  reportServiceUrl(tenant: string) {
    return `${PROTOCOL}://${tenant}.${baseUrl}/reporting/v1`;
  },

  accountServiceUrl() {
    return `${PROTOCOL}://${baseUrl}/account/v1`;
  },

  uploadServiceUrl() {
    return `${PROTOCOL}://${baseUrl}/upload/v1`;
  },

  paymentServiceUrl(tenant: string) {
    return `${PROTOCOL}://${tenant}.${baseUrl}/payment/v1`;
  },

  websocketServiceUrl(tenant: string) {
    return `${PROTOCOL}://${tenant}.${baseUrl}/websocket/v1`;
  },

  pdfServiceUrl() {
    return `${PROTOCOL}://${baseUrl}/pdf/v1`;
  },

  venueServiceUrl() {
    return `${PROTOCOL}://${baseUrl}/venue/v1`;
  },

  orderBookServiceUrl(tenant: string) {
    return `${PROTOCOL}://${tenant}.${baseUrl}/orderbook/v1`;
  },

  organizerUrl(tenant: string) {
    return `${PROTOCOL}://${tenant}.${baseUrl}/event/v1/organizer`;
  }
});
