<template>
  <div class="impersonated">
    <div class="MOBILE_h13_Semibold impersonated__user">
      {{ $t('impersonate.impersonatingUser') }} <br />
      <span class="MOBILE_h12_Regular impersonated__user">
        {{ impersonatedUser }}
      </span>
    </div>
    <PButton
      :label="$t('impersonate.endSession')"
      class="p-button-warning impersonated__button"
      data-cy="endImpersonationButton"
      @click="unImpersonate()"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PButton from 'primevue/button';
import { errorMessage } from '~/utils/utils';
export default {
  name: 'BImpersonated',
  components: {
    PButton
  },
  computed: {
    ...mapGetters('user', ['canAccessDashboard', 'getUser']),
    impersonatedUser() {
      const user = this.getUser;
      return user.firstName.length > 0
        ? `${user.firstName} ${user.lastName}`
        : user.email;
    }
  },
  methods: {
    async setOrganizers(user) {
      const permissions = user.permissions;
      const allOrganizers = Object.keys(permissions).filter(
        (org) => permissions[org].org_level || permissions[org].orgLevel
      );
      let organizers = await Promise.all(
        allOrganizers.map(
          async (org) => await this.$services.event.getOrganizer(org)
        )
      );
      organizers = organizers.filter((o) =>
        this.canAccessDashboard(this.$mainOrganizer, o.name)
      );
      this.$store.dispatch('app/setOrganizers', organizers);
      this.$store.dispatch('app/setOrganizer', organizers[0]);
    },
    async unImpersonate() {
      try {
        this.$store.dispatch('app/setLoading', true);
        const user = await this.$services.account.stopImpersonate(this.$auth);
        this.$store.dispatch('user/setUser', user);
        await this.setOrganizers(user);
        window.location.href = '/manage-accounts';
      } catch (error) {
        this.$notifier.showMessage({
          content: errorMessage(error, this.$t('errors.genericRequest')),
          type: 'error'
        });
      } finally {
        this.$store.dispatch('app/setLoading', false);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.impersonated {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__user {
    color: var(--v-textHighlightWarning-base);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 144px;
  }
  &__content {
    color: var(--v-textPrimary-lighten5);
    font-size: 12px;
    font-weight: bold;
    span {
      font-weight: 400;
      font-size: 14px;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    }
  }
  &__button {
    color: var(--v-textPrimary-lighten5);
  }
}
</style>
